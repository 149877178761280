<template>
  <div class="page">
    <BreadCrumb />
    <el-button class="goBack" plain round size="mini" type="primary" @click="goBack"> 返回 </el-button>
    <div class="page-body">
      <el-table v-loading="loading" :data="tableData" class="m-t-20" fit stripe>
        <el-table-column :index="(queryModule.currentPage - 1) * 5 + 1" label="序号" type="index" />
        <el-table-column label="ID" prop="id" />
        <el-table-column label="成功的名字" prop="qualifiedDataTabName" />
        <el-table-column label="失败的名字" prop="unqualifiedDataTabName" />
        <el-table-column label="规则数量" prop="ruleSize" />
        <el-table-column label="总量/匹配数量/匹配百分比/不匹配数量/不匹配百分比" prop="Num" />
        <el-table-column label="开始时间" prop="beginTime" />
        <el-table-column label="结束时间" prop="endTime" />
        <el-table-column label="耗时(s)" prop="timeConsum" />
        <el-table-column label="状态" prop="status" />
        <el-table-column label="进度">
          <template #default="scope">
            <el-progress :format="format" :percentage="scope.row.progress * 100" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip class="item" content="查看日志" effect="light" placement="top-end">
              <el-button circle size="mini" type="primary" @click="viewLog(scope.row)">
                <el-icon>
                  <i-fold />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-model:currentPage="queryModule.currentPage" :page-size="pageSize" :total="total" background layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
import { mapGetters } from 'vuex'

export default {
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      projectId: '', //返回用
      workId: '',
      loading: true,
      // table数据
      tableData: '', //当前页的 数据
      //翻页
      queryModule: {
        //查询
        totalElements: 0,
        currentPage: 1,
      },
      pageP1: '',
      pageP2: '',
      pageP3: '',
    }
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    this.pageP1 = Number(this.$route.query.pageP1)
    this.pageP2 = Number(this.$route.query.pageP2)
    this.workId = Number(this.$route.query.workId)
    this.projectId = Number(this.$route.query.projectId)
    if (this.$route.query.pageP3) {
      //说明是 返回上一页 进入的
      this.queryModule.currentPage = this.$route.query.pageP3 - 0
    }
    this.workAll()
  },
  methods: {
    //进度条
    format(percentage) {
      return percentage === 1 ? '满' : `${percentage.toFixed(1)}%`
    },
    //作业任务列表
    workAll() {
      var _this = this
      this.$axios
        .POST(`/api/v1/core/task/${_this.workId}/all`, {
          page: _this.queryModule.currentPage - 1,
          size: _this.pageSize,
        })
        .then(res => {
          let data = res.data
          _this.tableData = data.content
          for (let item of _this.tableData) {
            item['Num'] =
              item.taskResultResponse.totalNum +
              '/' +
              item.taskResultResponse.matchNum +
              '/' +
              item.taskResultResponse.matchRate +
              '%/' +
              item.taskResultResponse.mismatchNum +
              '/' +
              item.taskResultResponse.mismatchRate +
              '%'
            if (item['endTime']) {
              var data1 = new Date(item['endTime']),
                data2 = new Date(item['beginTime']),
                time2 = data2.getTime()
              item['timeConsum'] = _this.formatDuring(data1 - time2)
            }
          }
          _this.total = data.totalElements
          _this.loading = false
        })
    },
    formatDuring(mss) {
      var days = parseInt(mss / (1000 * 60 * 60 * 24)),
        hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60)),
        seconds = (mss % (1000 * 60)) / 1000
      return days + ' 天 ' + hours + ' 小时 ' + minutes + ' 分钟 ' + seconds + ' 秒 '
    },
    //查看任务日志
    viewLog(row) {
      this.pageP3 = this.queryModule.currentPage
      this.$router.push({
        name: 'viewLog',
        query: {
          taskId: row.id,
          workId: this.workId,
          projectId: this.projectId,
          pageP1: this.pageP1,
          pageP2: this.pageP2,
          pageP3: this.pageP3,
        },
      })
    },
    handleCurrentChange() {
      this.workAll()
    }, //返回上一级
    goBack() {
      //带参数跳转
      this.$router.push({
        name: 'projectDetails',
        query: { projectId: this.projectId, pageP1: this.pageP1, pageP2: this.pageP2 },
      })
    },
  },
}
</script>

<style scoped>
.el-table__row .cell {
  display: block;
}
</style>
